import { Component, OnInit, Input, Output, EventEmitter, ViewChild, VERSION } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CustomersService } from 'src/app/swagger-client/api/customers.service';
import { Customer } from 'src/app/swagger-client/model/customer';
import { Address } from 'src/app/swagger-client/model/address';
import { JsonAssetsService } from 'src/app/services/json-assets.services';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog, MatStepper } from '@angular/material';
import { DialogComponent } from '../shared/dialog/dialog.component';

export interface State {
  name: string;
  abbreviation: string;
}

@Component({
  selector: 'app-rq-profile',
  templateUrl: './rq-profile.component.html',
  styleUrls: ['./rq-profile.component.css']
})
export class RqProfileComponent implements OnInit {

  private assetsParams = '../../../assets/params.json';
  private validatorsForm: any;
  private customer: Customer;
  private addressCustomer: Address;
  formattedAddress: any;
  ngVersion: string = VERSION.full;
  matVersion = '5.1.0';
  @ViewChild('stepper', {static: false}) stepper: MatStepper;

  options = {
    componentRestrictions: { country: 'US' }
  };

  stateCtrl = new FormControl();
  filteredStates: Observable<State[]>;
  States: State[];

  @Input() profileForm: FormGroup;
  @Output() profileValidate = new EventEmitter<any>();

  constructor(private CustServices: CustomersService,
              private serviceJson: JsonAssetsService,
              private dialog: MatDialog) { }

  ngOnInit() {
    // this.serviceJson.get('../../../assets/states-usa.json')
    //   .subscribe(response => {
    //     if (response) {
    //       this.States = response.body;
    //       setTimeout(() => {
    //       this.filteredStatesFn();
    //       }, 500);
    //     }
    //   });
  }

  filteredStatesFn() {
    this.filteredStates = this.stateCtrl.valueChanges
    .pipe(
      startWith(''),
      map(state => state ? this._filterStates(state) : this.States.slice())
    );
  }

  private _filterStates(value: string): State[] {
    const filterValue = value.toLowerCase();
    return this.States.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
  }

  getErrorMessage(name: string) {

    switch (name) {
      case 'firstName':
      case 'lastName':
       return  this.profileForm.get(name).hasError('required') ? 'You must enter a value.' : '';
       break;
      case 'email':
       return  this.profileForm.get(name).hasError('required') ? 'You email is not valid.' :
               this.profileForm.get(name).hasError('email') ? 'Email incorrect, please validate.' : '';
       break;
      case 'address':
        return this.profileForm.get(name).hasError('required') ? 'Your address is not valid, more data is required.' : '';
        break;
      case 'phone':
        return this.profileForm.get(name).hasError('required') ? 'You must enter a value.' :
          this.profileForm.get(name).hasError('pattern') ? 'Invalid phone number pattern: ex) xxx-xxx-xxxx.' :
            this.profileForm.get(name).hasError('minlength') ? 'Invalid phone number length: ex) xxx-xxx-xxxx.' : '';
        break;
      case 'addresss':
       return 'Your addres is not valid';
       break;
    }
  }

  submit() {
    // console.log('Data profile:', this.profileForm.value);
    this.profileValidate.emit({ sppiner: true, index: 0});
    this.CustServices.createCustomer(this.setDataValue(this.profileForm))
      .subscribe(response => {
        if (response) {
          this.profileValidate.emit({ sppiner: false, index: 1 });
          localStorage.setItem('idCustomer', response.id);
        }
        console.log('Response profile -->', response);
    }, error => {
      // console.log('Response code error ---->', error.status);
      this.getDialog(error.error.message);
    } );
  }

  setDataValue(form: FormGroup): Customer {
    // console.log(form.getRawValue());
    const profile = form;
    this.customer = {
      id: '',
      firstName: profile.get('firstName').value,
      lastName: profile.get('lastName').value,
      address: this.addressCustomer,
      email: profile.get('email').value,
      phone: profile.get('phone').value
    };

    localStorage.setItem('customer', JSON.stringify(this.customer));

    console.log('Send customer --->', this.customer);
    return this.customer;
  }

  handleAddressChange(address: any) {
    const types = [];
    if (address.address_components.length <= 4) {
      // tslint:disable-next-line: no-unused-expression
      this.profileForm.get('address').setValue('');
    } else {
      this.addressCustomer = {
        street: '',
        city: '',
        state: '',
        zip: '0'
      };
      address.address_components.forEach(element => {
        types.push(element.types[0]);
        switch (element.types[0]) {
          case 'street_number':
          case 'route':
            this.addressCustomer.street = this.addressCustomer.street + ' ' + element.short_name;
            break;
          case 'locality':
            this.addressCustomer.city = element.short_name;
            break;
          case 'administrative_area_level_1':
            this.addressCustomer.state = element.short_name;
            break;
          case 'postal_code':
            this.addressCustomer.zip = element.short_name;
            break;
        }
      });
      // console.log('Complete address: ', this.addressCustomer);
    }
    // this.formattedAddress = address.address_components;
    // console.log(this.formattedAddress);
  }

  getDialog(error: any) {
    const dialog = this.dialog.open(DialogComponent, {
      width: '600px',
      data: {title: 'Error!',
             text: error,
             secondText: '',
             buttonCancel: 'Close'}

    });

    dialog.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // Nos quedamos en el step actual.
      this.profileValidate.emit({ sppiner: false, index: 0});
    });
    // dialog.close('Pizza!');
  }

  get firstName() { return this.profileForm.get('firstName'); }
  get lastName() { return this.profileForm.get('lastName'); }
  get email() { return this.profileForm.get('email'); }
  get phone() { return this.profileForm.get('phone'); }
  get addressCustom() { return this.profileForm.get('address'); }

}
