import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public menuData: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.menuData = {
      menuItems: [
        {code: 1, name: 'Home', route: '/home', icon: 'home'},
        {code: 1, name: 'Go Solar!', route: '/solar', icon: 'wb_sunny'},
        {code: 2, name: 'See Our Work', route: '/see', icon: 'home_work'},
        {code: 3, name: 'Why Solar?', route: '/why', icon: 'eco'},
        {code: 4, name: 'About Us', route: '/about', icon: 'info'},
        {code: 5, name: 'Contact Us', route: '/contact', icon: 'contact_mail'}
      ]
    };
    // console.log(this.menuData);
  }

  routeMenu(item: any) {
    this.router.navigate([item]);
  }

}
