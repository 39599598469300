import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faUserCheck, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { CustomersService } from 'src/app/swagger-client/api/customers.service';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material';


library.add(fas);
const glasses = findIconDefinition({ prefix: 'fas', iconName: 'glasses' });

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {

  faUserCheck = faUserCheck;
  faUserTimes = faUserTimes;

  public select = 0;

  dialogText = '<h3>What does desired system mean</h3><hr style="border-color: #8FC64D;"><p>By desired system, we mean the production' +
  'rate your desired system is rated at. This is measured in kilowatts(KW)</p><br><br><h3>How do I find out my system size?</h3>' +
  '<hr style="border-color: #8FC64D;"><p>We´ll help you figure out the right system that will suit your needs.<br></p>' +
  'Continue by selecting <label style="color: #8FC64D;"> Not Yet</label> and hitting <label>Next</label><br><br><br>' +
  '<h3>Why is this question being asked?</h3><hr style="border-color: #8FC64D;"><p>We liked to cut out the unnecessary fluff and get ' +
  'straight to bussines, and if you know exactly what you´re looking for, we´d love to give it to you right away! </p>';
  dialogSecondText = 'Is that okay?';

  @Input() optionForm: FormGroup;

  @Output() opt = new EventEmitter<any>();

  @Output() resetSteper = new EventEmitter<string>();

  constructor(private CustServices: CustomersService,
              private dialog: MatDialog) { }

  ngOnInit() {
    // this.optionForm.get('option').setValue('');
    // this.opt.emit({ option: true});
  }

  setValue(opt: number) {
    const option = opt === 1 ? true : false;
    this.optionForm.get('option').setValue(option);
    this.select = opt;
  }

  submit() {
    this.opt.emit(this.optionForm.value);
  }

  getDialog() {
    const dialog = this.dialog.open(DialogComponent, {
      width: '600px',
      data: {title: 'F.A.Q',
             text: '',
             secondText: ''
            }

    });

    dialog.componentInstance.htmlContent = this.dialogText;

    dialog.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  getBack() {
    this.resetSteper.emit('0');
  }
}
