import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-see-our-work',
  templateUrl: './see-our-work.component.html',
  styleUrls: ['./see-our-work.component.css']
})
export class SeeOurWorkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
