import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Customer } from 'src/app/swagger-client/model/customer';
import { CustomersService } from 'src/app/swagger-client/api/customers.service';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBillAlt, faHandHoldingUsd, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';

library.add(fas);
const glasses = findIconDefinition({ prefix: 'fas', iconName: 'glasses' });

@Component({
  selector: 'app-rq-pay',
  templateUrl: './rq-pay.component.html',
  styleUrls: ['./rq-pay.component.css']
})
export class RqPayComponent implements OnInit {

  faMoneyBillAlt = faMoneyBillAlt;
  faHandHoldingUsd = faHandHoldingUsd;
  faFileInvoiceDollar = faFileInvoiceDollar;

  public select: string;

  @Input() payForm: FormGroup;
  @Output() savings = new EventEmitter<any>();
  @Output() loadSavigs = new EventEmitter<boolean>();

  public optionsPay: any[] = [
    {
      name: 'Loan',
      value: 'Loan',
      img: faHandHoldingUsd
    },
    {
      name: 'Lease',
      value: 'Lease',
      img: faFileInvoiceDollar
    },
    {
      name: 'Cash',
      value: 'Cash',
      img: faMoneyBillAlt
    },

  ];
  private customer: Customer;

  dialogText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel scelerisque ex. Donec hendrerit eget turpis ac suscipit.';
  dialogSecondText = 'Is that okay?';

  constructor(private CustServices: CustomersService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.select = 'Loan';
  }

  getErrorMessage() {
    return this.payForm.get('option').hasError('required') ? 'You must enter a value' : '';
  }

  submit() {
    console.log('Data pay:', this.payForm.value);
    this.loadSavigs.emit(true);
    this.CustServices
      .udpateCustomer(localStorage.getItem('idCustomer'), this.setDataValue(this.payForm))
        .subscribe(response => {
          response ? this.getSavings() : console.log();
          // console.log('Pay:', response);
        });
  }

  setDataValue(form: FormGroup): Customer {
    const pay = form;
    this.customer = JSON.parse(localStorage.getItem('customer'));
    this.customer.paymentType = pay.get('option').value;

    return this.customer;
  }

  getSavings() {
    this.CustServices.getEstimatedSavings(localStorage.getItem('idCustomer'))
      .subscribe(response => {
        // console.log('Savings :', response);
        response ? this.loadSavigs.emit(false) : console.log();
        this.savings.emit(response);
      });
  }

  getDialog() {
    const dialog = this.dialog.open(DialogComponent, {
      width: '600px',
      data: {title: 'Wait!',
             text: this.dialogText,
             secondText: this.dialogSecondText,
             buttonCancel: 'Close'}

    });

    dialog.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
    // dialog.close('Pizza!');
  }

  setValue(opt: string) {
    this.payForm.get('option').setValue(opt);
    this.select = opt;
  }

  get option() { return this.payForm.get('option'); }

}
