import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.css']
})
export class WhyComponent implements OnInit {

  tittle: string;
  renewable = '';
  cost = 'With technological advancements in the solar industry, owning your own Solar System is not as expensive as it' +
  'seems with the U.S DOE estimating a 75% cost decrease from 2010, and additional 50% towards 2030. On top of that, there' +
  'are many ways to mitigate your month to month solar payments through different financing partners and payment methods.';
  maintainable = 'One thing to know is that solar production depends on the sun´s rays hitting the panels. With that in mind,' +
  'things like dust or shade will affect the solar production. Fortunately, cleaning dusto off your panel is not difficult,' +
  'and form more substantial grime, we provide services that make the experience simple and seamless. ';
  enviromental = 'Once a Sola System is on, it´s immediately giving back to the enviroment. Energy sources like fossil fuel are' +
  'enviromentally harmful, and ultamately not sustainable. Solar panel come with impact of the production like most thing, but thanks' +
  'to powerful technology, it is a long-standing investment and is a viable option to help care for the Eaarth  that we live on.';
  text: string;
  selected: number;
  footer: string;

  constructor() { }

  ngOnInit() {
    this.changeOpt(2);
  }

  changeOpt(opt: number)  {
    this.selected = opt;
    switch (opt) {
      case 1:
        this.text = this.renewable;
        break;
      case 2:
        this.tittle = 'It doesn´t cost an arm, or leg!';
        this.text = this.cost;
        this.footer = 'Traking the Sun, Berkley Labs 2019';
        break;
      case 3:
        this.tittle = 'Solar panels are proven to last over 25 years';
        this.text = this.maintainable;
        this.footer = 'Protovoltaic Degradation Rates, National Renewable Energy Laboratory 2019';
        break;
      case 4:
        this.tittle = 'It´s easy to be green';
        this.text = this.enviromental;
        this.footer = 'Environmental Impacts of Solar Power, UCSUSA 2013';
        break;
      default:
        break;
    }

    console.log('Option go solar --->', this.text);
  }

}
