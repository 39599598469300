import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsonAssetsService {

  headers: HttpHeaders;
  constructor(
    private httpClient: HttpClient
  ) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  getInformation(endPonit: string): Observable<any> {
    return this.get(endPonit).pipe( map(res => {
      return this.map(res);
    }));
  }

  get(endpoint: string): Observable<any>  {
    // const options = new HttpRequest('GET', `${environment.base}/${endpoint}`, this.headers);
    // return this.httpClient.request(options);
    return;
  }

  map(res: any): any {
    if (!res.body) {
      return null;
    }
    return res.body;
  }

}
