import {Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {Customer} from 'src/app/swagger-client/model/customer';
import {CustomersService} from 'src/app/swagger-client/api/customers.service';
import {SolarService} from 'src/app/swagger-client/api/solar.service';
import {Address} from '../../model/custom.model';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-rq-size',
  templateUrl: './rq-size.component.html',
  styleUrls: ['./rq-size.component.css']
})
export class RqSizeComponent implements OnInit, OnChanges {

  @Input() sizeForm: FormGroup;
  @Input() usageForm: FormGroup;
  @Input() option: boolean;
  // type pay.
  @Output() savings = new EventEmitter<any>();
  @Output() loadSavigs = new EventEmitter<boolean>();

  @Output() loadPay = new EventEmitter<boolean>();

  public opt: boolean;
  private customer: Customer;
  private address: Address;
  public systemSize = true;
  public showSlider = false;
  public min: number;
  public max: number;
  public form: FormGroup;
  private sizeSlider: any;

  showDialog = false;

  dialogText = 'Without your annual electricity cost, we can only give you an estimation of you savings';
  dialogSecondText = 'Is that okay?';

  // tslint:disable-next-line: max-line-length
  private FAQ1 = '<h3>Is my  annual electricity cost needed?</h3><hr class="line-green"><p>We´re hoping to provide for you run-down of your savings by doing' +
  ' solar! However, if you´re uncomfortable with giving your annual electricity cost, don´t worry about it-just continue without it.</p>' +
  // tslint:disable-next-line: max-line-length
  '<br><br><h3>Why is system size needed?</h3><hr class="line-green"><p>At the end of the day, what we provided for you is a Solar System, and if you ' +
  // tslint:disable-next-line: max-line-length
  'already know what size you want that system to be, let´s get started!</p><br><br><h3>How do you I  figure out my system size?</h3><hr class="line-green">' +
  '<p>No worries, we´ll help you figure out what system size suits your needs.</p><br><a href="">Figure out my system size</a>';
  // tslint:disable-next-line: max-line-length
  private FAQ2 = '<h3>Is my annual electricity cost needed?</h3><hr class="line-green"><p>We´re hoping to provide for you a run-down of your savings by ' +
  'going solar! However, if you´re uncomfortable with giving your annual electricity cost, don´t worry about it-just continue without it' +
  // tslint:disable-next-line: max-line-length
  '</p><br><br><h3>How do I find my annual ussage?</h3><hr class="line-green"><p>The best way to find your annual ussage is on your electricity bill,' +
  'you may have to log into your electricity provider´s site to retrieve the information.</p><br><br><h3>How do I read my electricity ' +
  // tslint:disable-next-line: max-line-length
  'bill</h3><hr class="line-green"><p>Here are some company-specific guides to understanding your electric bill</p><a href="">Pacific Gas and Electric' +
  '</a><br><a href="">San Diego Gas and Electric</a><br><a href="">Southern California Edison</a><br><a href="">Turlock Irrigation ' +
  // tslint:disable-next-line: max-line-length
  'District</a><br><br><h3>I don´t know my annual usage</h3><hr class="line-green><p>That´s okay, we can pass the work to one of our representatives.</p>';

  constructor(private dialog: MatDialog,
              private custServices: CustomersService,
              private solarServices: SolarService,
              private pipeMoney: CurrencyPipe) { }

  ngOnInit() {
    // console.log('Size:', this.option);
  }

  getErrorMessage() {
    switch (name) {
      case 'sizeSystem':
      case 'usageSystem':
      case 'fill':
        return 'You must enter a value';
        break;
    }
  }

  getSize(size: any) {
    if (size !== '') {
      this.systemSize = false;
      this.showSlider = false;
      const addresSize = this.addressParse();
      this.solarServices
        .getEstimatedSystemSize(Number(size),
          addresSize.street, addresSize.city, addresSize.state, addresSize.zip, )
        .subscribe(response => {
          if (response) {
            // tslint:disable-next-line: no-string-literal
            this.usageForm.get('fill').setValue(response['estimatedSystemSize']);
            this.showSlider = true;
            this.systemSize = true;
            this.sizeSlider = response['estimatedSystemSize'];
            // tslint:disable-next-line: no-string-literal
            this.min = Number(Number(((-20 * response['estimatedSystemSize']) / 100) + (response['estimatedSystemSize'])).toFixed(2));
            // tslint:disable-next-line: no-string-literal
            this.max = Number(Number(((20 * response['estimatedSystemSize']) / 100) + (response['estimatedSystemSize'])).toFixed(2));
          }
          // console.log('Response systemSize :', response);
        }, error => {
          console.log('Error usage KwH:', error.error.message);
        });
    }
  }

  submit(opt: boolean, dialog?: number) {
    dialog === 1 ? console.log() : this.loadPay.emit(true);
    const form = opt ? this.sizeForm : this.usageForm;
    !this.showDialog ?
    opt ?
    !this.sizeForm.get('costAnnual').value ?
      this.getDialog(true, opt ? true : false) : this.sendData(form) :
       !this.usageForm.get('costAnnual').value ?
        this.getDialog(true, opt ? true : false) : this.sendData(form) : this.sendData(form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.option) {
      this.opt = changes.option.currentValue.option;
      this.opt ? this.form = this.sizeForm : this.form = this.usageForm;
      // console.log('Form select ---->', this.form.value);
    }
  }

  setDataValue(form: FormGroup): Customer {
    const tpform = form;
    this.customer = JSON.parse(localStorage.getItem('customer'));
    this.customer.paymentType = 'LOAN';
    this.customer.annualCost = tpform.get('costAnnual').value ?
       Number(tpform.get('costAnnual').value.replace(/[^0-9.-]+/g, '')) : 0.01;
    if (this.opt) {
      this.customer.systemSize = tpform.get('sizeSystem').value;
      this.customer.annualUsage = null;
    } else {
      this.customer.systemSize = this.usageForm.get('fill').value;
      // this.sizeForm.get('sizeSystem').setValue('1');
      this.customer.annualUsage = tpform.get('usageSystem').value;
    }

    localStorage.setItem('customer', JSON.stringify(this.customer));
    console.log('Payload Size -->', this.customer);
    return this.customer;
  }

  addressParse(): Address {
    this.address = JSON.parse(localStorage.getItem('customer')).address;
    return this.address;
  }

  getDialog(opt: boolean, form: boolean) {
    let dialog;
    if (opt) {
      dialog = this.dialog.open(DialogComponent, {
        width: '600px',
        data: {title: 'Wait!',
               text: this.dialogText,
               secondText: this.dialogSecondText,
               buttonOk: 'Yes',
               buttonCancel: 'No',
               form: 'size'}
      });
    } else {
      dialog = this.dialog.open(DialogComponent, {
        width: '600px',
        data: {title: 'F.A.Q',
               text: '',
               secondText: '',
               form: !form ? 'ussage' : 'size'}
      });
      // type of form true SIZE false USSAGE
      form ?
      dialog.componentInstance.htmlContent = this.FAQ1 :
      dialog.componentInstance.htmlContent = this.FAQ2;
    }
    dialog.afterClosed().subscribe((result: boolean) => {
      this.showDialog = result;
    });
  }

  sendData(form: any) {
    this.loadSavigs.emit(true);
    this.custServices
        .udpateCustomer(localStorage.getItem('idCustomer'), this.setDataValue(form))
        .subscribe(response => {
          // console.log('Response submit :', response);
          // this.loadPay.emit(false);
          this.getSavings(); // ** Nos traemos los ahorros del servicio, ahora no funciona el servicio.
          // response ? this.loadSavigs.emit(false) : console.log();
          // this.savings.emit(response);
          console.log('Pay:', response);
        }, error => {
          this.getDialogError(error.error.message);
        });
  }

  changeCost(value: any, form: any) {
    if (value !== '') {
      if (form === 'size') {
        this.showDialog = true;
        this.sizeForm.get('costAnnual').setValue(this.pipeMoney.transform(value));
      } else {
        this.showDialog = true;
        this.usageForm.get('costAnnual').setValue(this.pipeMoney.transform(value));
      }
    } else {
      this.showDialog = false;
    }
  }

  // postPay(form: any) {
  //   this.custServices
  //     .udpateCustomer(localStorage.getItem('idCustomer'), this.setDataValue(form))
  //       .subscribe(response => {
  //       });
  // }

  getSavings() {
    this.custServices.getEstimatedSavings(localStorage.getItem('idCustomer'))
      .subscribe(response => {
        console.log('Savings :', response);
        response ? this.loadSavigs.emit(false) : console.log();
        this.savings.emit(response);
      }, error => {
        console.log('Error estimatedsavings -->', error);
        this.getDialogError(error.error.message);
      });
  }

  getDialogError(error: any) {
    this.loadPay.emit(false);
    const dialog = this.dialog.open(DialogComponent, {
      width: '600px',
      data: {title: 'Error!',
             text: error,
             secondText: '',
             buttonCancel: 'Close'}

    });

    dialog.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
    // dialog.close('Pizza!');
  }

  get sizeSystem() { return this.sizeForm.get('sizeSystem'); }
  get usageSystem() { return this.usageForm.get('usageSystem'); }
  get fill() { return this.usageForm.get('fill'); }
}
