import { Component, OnInit } from '@angular/core';
import { faPhoneAlt, faUserSlash, faEnvelope, faCheckCircle, faFrown } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {

  faPhoneAlt = faPhoneAlt;
  faUserSlash = faUserSlash;
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;
  faFrown = faFrown;

  public selectopt = '';
  public ok: number;

  constructor() { }

  ngOnInit() {
    this.selectopt = 'phone';
  }

  setValue(opt: any) {
    // opt ? this.optionForm.get('option').setValue(true) : this.optionForm.get('option').setValue(false);
    this.selectopt = opt;
  }

  submit() {
    this.selectopt === 'phone' || this.selectopt === 'email' ? this.ok = 1 :
    this.selectopt === 'contact' ? this.ok = 2 : console.log();
  }

  getBack() {
  }

}
