import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { CustomersService } from 'src/app/swagger-client/api/customers.service';
import {Body} from 'src/app/swagger-client/model/body';

@Component({
  selector: 'app-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.css']
})
export class SavingsComponent implements OnInit, OnChanges {

  @Input() moneySaving: any;
  selectedPay: any;
  saving: number;

  responseSignAgree: any;
  responseURLPSA: any;
  endView: boolean;
  showSppiner: boolean;

  showDialog = false;

  FAQ1 = 'PSA stands for Pre-Site Assessment. What happends during a PSA is our team will ' +
         'inspect the property to determine what needs to happen for a solar system installation.';
  FAQ2 = 'In this time, we´ll be looking at any potential issues or hazards that would get in the way ' +
          'of installation. Our goal it to make sure your solar system  is operational as quickly, professionally, ' +
          'and safely as possible.';

  showThank: boolean;

  constructor(private dialog: MatDialog,
              private custServices: CustomersService) { }

  ngOnInit() {
    console.log('View Savings value:', this.moneySaving);
    this.selectedPay = 'LOAN';
    this.changePay(this.selectedPay);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.moneySaving) {
      changes.moneySaving.currentValue === 'endView' ?
      this.endView = true : console.log('Texto solar:', changes.moneySaving);
    }
  }

  getDialog(error: any) {
    let dialog;
    dialog = this.dialog.open(DialogComponent, {
        width: '600px',
        data: {title: 'Error!',
               text: error,
               secondText: '',
               buttonOk: 'Ok'}
      });

    dialog.afterClosed().subscribe((result: boolean) => {
      this.showDialog = result;
    });
  }

  changePay(value: any) {
    this.selectedPay = value;
    // Aqui van los ahorro que se traiga del servicios de savings.
    value === 'LOAN' ?
      this.saving = this.moneySaving.loanMonthlyPayment :
       this.saving = this.moneySaving.totalCashPrice;
  }

  createAgreement() {
    this.showSppiner = true;
    const body: Body = { type: Body.TypeEnum.INTENT};
    this.custServices.createAgreement(localStorage.getItem('idCustomer'), body)
      .subscribe(respAgree => {
        this.showSppiner = false;
        console.log('Response create Agree:', respAgree);
        localStorage.setItem('idAgreement', respAgree.id);
        respAgree ? this.getUrlAgreement() : console.log();
      }, error => {
        console.log('Error create agreement:', error.error.message);
        this.showSppiner = false;
        this.getDialog(error.error.message);
      });
  }

  // Retrieves agreement url and redirects
  getUrlAgreement() {
    this.showSppiner = true ;
    this.custServices.getSigningUrl(localStorage.getItem('idCustomer'), localStorage.getItem('idAgreement'))
      .subscribe(response => {
        console.log('Response getURLAgreement:', response);
        window.location.href = response.signingUrl;
      }, error => {
        this.showSppiner = false;
        this.getDialog(error.error.message);
      });
  }

  finallyView() {
    console.log('Finally view');
    this.endView = true;
  }

}
