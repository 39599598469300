import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rq-address',
  templateUrl: './rq-address.component.html',
  styleUrls: ['./rq-address.component.css']
})
export class RqAddressComponent implements OnInit {


  @Input() addressForm: FormGroup;

  constructor() { }

  ngOnInit() {
  }

  getErrorMessage() {
    return this.addressForm.get('address').hasError('required') ? 'You must enter a value' : '';
    return this.addressForm.get('city').hasError('required') ? 'You must enter a value' : '';
    return this.addressForm.get('zip').hasError('required') ? 'You must enter a value' : '';
    return this.addressForm.get('state').hasError('required') ? 'You must enter a value' : '';
  }

  submit() {
    // console.log('Data address:', this.addressForm.value);
  }

  get address() { return this.addressForm.get('address'); }
  get city() { return this.addressForm.get('city'); }
  get zip() { return this.addressForm.get('zip'); }
  get state() { return this.addressForm.get('state'); }
}
