import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ContentComponent } from './components/shared/content/content.component';
import { StartComponent } from './components/start/start.component';
import { HomeComponent } from './components/home/home.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RqProfileComponent } from './components/rq-profile/rq-profile.component';
import { RqAddressComponent } from './components/rq-address/rq-address.component';
import { OptionsComponent } from './components/options/options.component';
import { RqSizeComponent } from './components/rq-size/rq-size.component';
import { RqPayComponent } from './components/rq-pay/rq-pay.component';
import { SavingsComponent } from './components/savings/savings.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { WhyComponent } from './components/why/why.component';
import { SeeOurWorkComponent } from './components/see-our-work/see-our-work.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatCardModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { JsonAssetsService } from './services/json-assets.services';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { FormComponent } from './components/shared/form/form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RqUsageComponent } from './components/rq-usage/rq-usage.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from './components/shared/dialog/dialog.component';
import { CustomersService } from 'src/app/swagger-client/api/customers.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SolarService } from 'src/app/swagger-client/api/solar.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CurrencyPipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ThanksComponent } from './components/thanks/thanks.component';
import { ContractComponent } from './components/contract/contract.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    StartComponent,
    HomeComponent,
    RqProfileComponent,
    RqAddressComponent,
    OptionsComponent,
    RqSizeComponent,
    RqPayComponent,
    SavingsComponent,
    AboutComponent,
    ContactComponent,
    WhyComponent,
    SeeOurWorkComponent,
    NotFoundComponent,
    FormComponent,
    RqUsageComponent,
    DialogComponent,
    ThanksComponent,
    ContractComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    MatGridListModule,
    HttpClientModule,
    MatButtonModule,
    MatStepperModule,
    MatRadioModule,
    FontAwesomeModule,
    MatSliderModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    GooglePlaceModule,
    MatButtonToggleModule
  ],
  providers: [
    JsonAssetsService,
    CustomersService,
    SolarService,
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }
