import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rq-usage',
  templateUrl: './rq-usage.component.html',
  styleUrls: ['./rq-usage.component.css']
})
export class RqUsageComponent implements OnInit {

  @Input() usageForm: FormGroup;

  constructor() { }

  ngOnInit() {
  }

  getErrorMessage() {
    return this.usageForm.get('usageSystem').hasError('required') ? 'You must enter a value' : '';
    return this.usageForm.get('fill').hasError('required') ? 'You must enter a value' : '';
  }

  submit() {
    console.log('Data usage:', this.usageForm.value);
  }

  get usageSystem() { return this.usageForm.get('usageSystem'); }
  get fill() { return this.usageForm.get('fill'); }

}
