import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatStepper, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CustomersService } from 'src/app/swagger-client/api/customers.service';
import { DialogComponent } from '../dialog/dialog.component';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  isLinear = false;
  profileForm: FormGroup;
  addressForm: FormGroup;
  optionForm: FormGroup;
  sizeForm: FormGroup;
  payForm: FormGroup;
  usageForm: FormGroup;
  showSppiner = false;

  bckground = '../../../../assets/Background.jpg';

  steps: any;
  opt = { option: true};
  showSavings = false;
  savingsText: string;
  form: string;
  opportunitiesV: boolean;
  retry = 0;

  htmlContent: string;
  index: number;

  routerExt: any;
  showRoutePSA = false;

  private dialogTextOpt = '<h3>What does desired system mean</h3><hr class="line-green"><p>By desired system, we mean the production' +
  'rate your desired system is rated at. This is measured in kilowatts(KW)</p><br><br><h3>How do I find out my system size?</h3>' +
  '<hr class="line-green"><p>We´ll help you figure out the right system that will suit your needs.<br></p>' +
  'Continue by selecting <label style="color: #8FC64D;"> Not Yet</label> and hitting <label>Next</label><br><br><br>' +
  '<h3>Why is this question being asked?</h3><hr class="line-green""><p>We liked to cut out the unnecessary fluff and get ' +
  'straight to bussines, and if you know exactly what you´re looking for, we´d love to give it to you right away! </p>';
  private FAQ1 = '<h3>Size Is my  annual electricity cost needed?</h3><hr class="line-green"><p>We´re hoping to provide for you run-down of your savings by doing' +
  ' solar! However, if you´re uncomfortable with giving your annual electricity cost, don´t worry about it-just continue without it.</p>' +
  '<br><br><h3>Why is system size needed?</h3><hr class="line-green"><p>At the end of the day, what we provided for you is a Solar System, and if you ' +
  'already know what size you want that system to be, let´s get started!</p><br><br><h3>How do you I  figure out my system size?</h3><hr class="line-green">' +
  '<p>No worries, we´ll help you figure out what system size suits your needs.</p><br><a href="">Figure out my system size</a>';
  private FAQ2 = '<h3>Usage Is my annual electricity cost needed?</h3><hr class="line-green"><p>We´re hoping to provide for you a run-down of your savings by ' +
  'going solar! However, if you´re uncomfortable with giving your annual electricity cost, don´t worry about it-just continue without it' +
  '</p><br><br><h3>How do I find my annual ussage?</h3><hr class="line-green"><p>The best way to find your annual ussage is on your electricity bill,' +
  'you may have to log into your electricity provider´s site to retrieve the information.</p><br><br><h3>How do I read my electricity ' +
  'bill</h3><hr class="line-green"><p>Here are some company-specific guides to understanding your electric bill</p><a href="">Pacific Gas and Electric' +
  '</a><br><a href="">San Diego Gas and Electric</a><br><a href="">Southern California Edison</a><br><a href="">Turlock Irrigation ' +
  'District</a><br><br><h3>I don´t know my annual usage</h3><hr class="line-green><p>That´s okay, we can pass the work to one of our representatives.</p>';

  private assetsParams = '../../../assets/params.json';

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private custServices: CustomersService,
              private dialog: MatDialog) {}

  ngOnInit() {
    // this.getServiceAssets();
    this.route.params.subscribe((params: any) => {
      if (params.route !== undefined) {
        localStorage.setItem('idCustomer', params.idCustomer);
        this.routerExt = params.route;
        if (this.routerExt === 'psa') {
          this.showRoutePSA = true;
          this.opportunities();
        } else {
          this.showSavings = true;
          this.savingsText = 'endView';
        }
      } else {
        this.showSavings = false;
      }
    });
    this.createForm();
  }

  getServiceInfo(data: any) {
    console.log('Data:', data);
    this.steps = data.stepsForm;
    console.log(this.steps);
  }

  createForm(): void {
    // Profile Form and Address Form
    this.profileForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        Validators.pattern('^[0-9_-]{10,12}')
      ]],
      address: ['', [Validators.required]]
    });
    // Options Form
    this.optionForm = this.formBuilder.group({
        option: ['', [Validators.required]]
      });
    // Size Form
    this.sizeForm = this.formBuilder.group({
      costAnnual: [''],
      sizeSystem: ['', [Validators.required]]
    });
    // Usage Form
    this.usageForm = this.formBuilder.group({
      costAnnual: [''],
      usageSystem: ['', [Validators.required]],
      fill: ['', [Validators.required]]
    });
    // Pay Form
    this.payForm = this.formBuilder.group({
      option: ['Loan', [Validators.required]]
    });
  }

  getOption(opt: any) {
    console.log('Data options:', opt);
    this.opt = opt;
    opt.option ? this.form = 'sizeForm' : this.form = 'usageForm';
    this.htmlContent = '';
    opt.option ? this.htmlContent = this.FAQ1 : this.htmlContent = this.FAQ2;
  }

  getProfile(event: any) {
    this.showSppiner = event.sppiner;
    this.index = event.index;
    event.index === 0 ? console.log('Step-->', event.index) : console.log('Step -->', event.index);
    this.stepper.selectedIndex = event.index;
    this.htmlContent = this.dialogTextOpt;
  }

  loadSavings(event: boolean) {
    this.showSppiner = event;
  }

  loadPay(event: boolean) {
    this.showSppiner = event;
  }

  getSavings(value: any) {
    console.log('Savings ready:', value);
    this.showSavings = true;
    this.savingsText = value;
  }

  getSchedulePSA() {
    this.custServices.getSchedulePSAUrl(localStorage.getItem('idCustomer'))
      .subscribe(response => {
        this.showRoutePSA = false;
        console.log('Response URL PSA:', response);
        if (response) {
          window.location.href = response.schedulePSAUrl;
        }
      }, error => {
        this.dialogError(error.error.message);
      });
  }

  routeThanks() {
    this.router.navigate(['/thank']);
  }

  opportunities() {
    this.custServices.createCustomerOpportunity(localStorage.getItem('idCustomer'))
      .subscribe(response => {
        this.getSchedule(response.message);
        // console.log('Opportunities --->', this.opportunitiesV);
      }, error => {
        // console.log('Error opportunities -->', error.error.message);
        this.dialogError(error.error.message);
      });
  }

  getSchedule(opt: any) {
    opt === 'ok' ? this.getSchedulePSA() : this.dialogError('There was a problem requesting the appointment.');
  }

  dialogError(error: any) {
    this.retry = this.retry + 1;
    let dialog;
    if (this.retry < 3) {
      dialog = this.dialog.open(DialogComponent, {
        width: '600px',
        data: {title: '¡Oopss!',
               text: error,
               secondText: 'Try again please.',
               buttonOk: 'Retry'}
      });
      dialog.afterClosed().subscribe((result: boolean) => {
       this.ngOnInit();
      });
    } else {
      dialog = this.dialog.open(DialogComponent, {
        width: '600px',
        data: {title: 'We are sorry.',
               text: 'Your appointment cannot be generated at this time.',
               secondText: '',
               buttonOk: 'Close'}
      });
      dialog.afterClosed().subscribe((result: boolean) => {
        // this.showDialog = result;
          this.showRoutePSA = false;
      });
    }
  }
}
