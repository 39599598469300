import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  htmlContent: string;
  form: string;

  constructor( public dialogRef: MatDialogRef<DialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private router: Router,
               private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    console.log('Dialog Data --->', this.data.form);
    this.form = this.data.form;
  }

  click(opt: boolean): void {
    this.dialogRef.close(opt);
    !this.data.text ? this.route() : console.log();
  }

  route() {
    this.router.navigate(['/thank']);
  }

}
