/**
 * Direct Sales API
 * Direct Sales API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EstimateSystemSize } from '../model/estimateSystemSize';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SolarService {

    protected basePath = '/dev';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     * returns estimated system size given annual usage and address
     * @param annualUsage annual usage in kWH
     * @param street street address
     * @param city city address
     * @param state state address
     * @param zipCode zip code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEstimatedSystemSize(annualUsage: number, street: string, city: string, state: string, zipCode: string, observe?: 'body', reportProgress?: boolean): Observable<EstimateSystemSize>;
    public getEstimatedSystemSize(annualUsage: number, street: string, city: string, state: string, zipCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EstimateSystemSize>>;
    public getEstimatedSystemSize(annualUsage: number, street: string, city: string, state: string, zipCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EstimateSystemSize>>;
    public getEstimatedSystemSize(annualUsage: number, street: string, city: string, state: string, zipCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (annualUsage === null || annualUsage === undefined) {
            throw new Error('Required parameter annualUsage was null or undefined when calling getEstimatedSystemSize.');
        }

        if (street === null || street === undefined) {
            throw new Error('Required parameter street was null or undefined when calling getEstimatedSystemSize.');
        }

        if (city === null || city === undefined) {
            throw new Error('Required parameter city was null or undefined when calling getEstimatedSystemSize.');
        }

        if (state === null || state === undefined) {
            throw new Error('Required parameter state was null or undefined when calling getEstimatedSystemSize.');
        }

        if (zipCode === null || zipCode === undefined) {
            throw new Error('Required parameter zipCode was null or undefined when calling getEstimatedSystemSize.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (annualUsage !== undefined && annualUsage !== null) {
            queryParameters = queryParameters.set('annual_usage', <any>annualUsage);
        }
        if (street !== undefined && street !== null) {
            queryParameters = queryParameters.set('street', <any>street);
        }
        if (city !== undefined && city !== null) {
            queryParameters = queryParameters.set('city', <any>city);
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (zipCode !== undefined && zipCode !== null) {
            queryParameters = queryParameters.set('zip_code', <any>zipCode);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EstimateSystemSize>('get',`${this.basePath}/solar/estimated-system-size`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
