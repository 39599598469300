import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { StartComponent } from './components/start/start.component';
import { RqProfileComponent } from './components/rq-profile/rq-profile.component';
import { RqAddressComponent } from './components/rq-address/rq-address.component';
import { OptionsComponent } from './components/options/options.component';
import { RqSizeComponent } from './components/rq-size/rq-size.component';
import { RqPayComponent } from './components/rq-pay/rq-pay.component';
import { SavingsComponent } from './components/savings/savings.component';
import { WhyComponent } from './components/why/why.component';
import { SeeOurWorkComponent } from './components/see-our-work/see-our-work.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ContentComponent } from './components/shared/content/content.component';
import { ThanksComponent } from './components/thanks/thanks.component';


const routes: Routes = [
  { path: '', redirectTo: '/content', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'start', component: StartComponent },
  { path: 'solar', component: RqProfileComponent },
  // { path: 'address', component: RqAddressComponent },
  // { path: 'options', component: OptionsComponent },
  // { path: 'size', component: RqSizeComponent },
  // { path: 'pay', component: RqPayComponent },
  // { path: 'savings', component: SavingsComponent },
  { path: 'why', component: WhyComponent },
  { path: 'see', component: SeeOurWorkComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'content', component: ContentComponent },
  { path: 'content/:route/:idCustomer', component: ContentComponent },
  { path: 'thank', component: ThanksComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
