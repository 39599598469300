/**
 * Direct Sales API
 * Direct Sales API
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Body { 
    type: Body.TypeEnum;
}
export namespace Body {
    export type TypeEnum = 'LETTER OF INTENT';
    export const TypeEnum = {
        INTENT: 'LETTER OF INTENT' as TypeEnum
    };
}