/**
 * Direct Sales API
 * Direct Sales API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Agreement } from '../model/agreement';
import { Body } from '../model/body';
import { Customer } from '../model/customer';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { Saving } from '../model/saving';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomersService {

    protected basePath = '/dev';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     * Creates and sends an agreement to customer
     * @param customerId Id of customer
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgreement(customerId: string, body?: Body, observe?: 'body', reportProgress?: boolean): Observable<Agreement>;
    public createAgreement(customerId: string, body?: Body, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agreement>>;
    public createAgreement(customerId: string, body?: Body, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agreement>>;
    public createAgreement(customerId: string, body?: Body, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling createAgreement.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Agreement>('post',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/agreements`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Creates a new customer. Duplicates are not allowed. Customers are uniquely identified by their first name, last name, and address.
     * @param body Customer Request Body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomer(body?: Customer, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public createCustomer(body?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public createCustomer(body?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public createCustomer(body?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Customer>('post',`${this.basePath}/customers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * creates an opportunity for customer
     * @param customerId Id of customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerOpportunity(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public createCustomerOpportunity(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public createCustomerOpportunity(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public createCustomerOpportunity(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling createCustomerOpportunity.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse2001>('put',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/opportunities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Returns an existing customer by id.
     * @param customerId Id of customer to fetch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCustomerById(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public findCustomerById(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public findCustomerById(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public findCustomerById(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling findCustomerById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Customer>('get',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * get agreement by customer id and agreement id
     * @param customerId Id of customer
     * @param agreementId id of agreement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgreement(customerId: string, agreementId: string, observe?: 'body', reportProgress?: boolean): Observable<Agreement>;
    public getAgreement(customerId: string, agreementId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agreement>>;
    public getAgreement(customerId: string, agreementId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agreement>>;
    public getAgreement(customerId: string, agreementId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getAgreement.');
        }

        if (agreementId === null || agreementId === undefined) {
            throw new Error('Required parameter agreementId was null or undefined when calling getAgreement.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Agreement>('get',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/agreements/${encodeURIComponent(String(agreementId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * get all agreements for customer
     * @param customerId Id of customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgreementsByCustomerId(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agreement>>;
    public getAllAgreementsByCustomerId(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agreement>>>;
    public getAllAgreementsByCustomerId(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agreement>>>;
    public getAllAgreementsByCustomerId(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getAllAgreementsByCustomerId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Agreement>>('get',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/agreements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Get customer by first name, last name, and address
     * @param firstName first name of customer
     * @param lastName last name of customer
     * @param street street address
     * @param city city address
     * @param state state address
     * @param zipCode zip code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerByFirstNameLastNameAndAddress(firstName: string, lastName: string, street: string, city: string, state: string, zipCode: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomerByFirstNameLastNameAndAddress(firstName: string, lastName: string, street: string, city: string, state: string, zipCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomerByFirstNameLastNameAndAddress(firstName: string, lastName: string, street: string, city: string, state: string, zipCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomerByFirstNameLastNameAndAddress(firstName: string, lastName: string, street: string, city: string, state: string, zipCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (firstName === null || firstName === undefined) {
            throw new Error('Required parameter firstName was null or undefined when calling getCustomerByFirstNameLastNameAndAddress.');
        }

        if (lastName === null || lastName === undefined) {
            throw new Error('Required parameter lastName was null or undefined when calling getCustomerByFirstNameLastNameAndAddress.');
        }

        if (street === null || street === undefined) {
            throw new Error('Required parameter street was null or undefined when calling getCustomerByFirstNameLastNameAndAddress.');
        }

        if (city === null || city === undefined) {
            throw new Error('Required parameter city was null or undefined when calling getCustomerByFirstNameLastNameAndAddress.');
        }

        if (state === null || state === undefined) {
            throw new Error('Required parameter state was null or undefined when calling getCustomerByFirstNameLastNameAndAddress.');
        }

        if (zipCode === null || zipCode === undefined) {
            throw new Error('Required parameter zipCode was null or undefined when calling getCustomerByFirstNameLastNameAndAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('first_name', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('last_name', <any>lastName);
        }
        if (street !== undefined && street !== null) {
            queryParameters = queryParameters.set('street', <any>street);
        }
        if (city !== undefined && city !== null) {
            queryParameters = queryParameters.set('city', <any>city);
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (zipCode !== undefined && zipCode !== null) {
            queryParameters = queryParameters.set('zip_code', <any>zipCode);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Customer>('get',`${this.basePath}/customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Get estimated saving for customer. Referenced customer must have a system size and payment type.
     * @param customerId Id of customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEstimatedSavings(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Saving>;
    public getEstimatedSavings(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Saving>>;
    public getEstimatedSavings(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Saving>>;
    public getEstimatedSavings(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEstimatedSavings.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Saving>('get',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/savings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * get schedule psa url. URL will only be availabile until customer has signed letter of intent agreement
     * @param customerId Id of customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSchedulePSAUrl(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public getSchedulePSAUrl(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public getSchedulePSAUrl(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public getSchedulePSAUrl(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getSchedulePSAUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse200>('get',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/schedule-psa-url`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * get the signing url for an agreement. Once customer has signed agreement will be redirect to application
     * @param customerId Id of customer
     * @param agreementId id of agreement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSigningUrl(customerId: string, agreementId: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public getSigningUrl(customerId: string, agreementId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public getSigningUrl(customerId: string, agreementId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public getSigningUrl(customerId: string, agreementId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getSigningUrl.');
        }

        if (agreementId === null || agreementId === undefined) {
            throw new Error('Required parameter agreementId was null or undefined when calling getSigningUrl.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InlineResponse2002>('get',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/agreements/${encodeURIComponent(String(agreementId))}/signing-url`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Updates a customer by id
     * @param customerId id of customer to update
     * @param body Customer Request Body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public udpateCustomer(customerId: string, body?: Customer, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public udpateCustomer(customerId: string, body?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public udpateCustomer(customerId: string, body?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public udpateCustomer(customerId: string, body?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling udpateCustomer.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Upload or replace the utility bill for related customer. Will set the customer&#x27;s utilityBillProvided flag to true.
     * @param body blob of utility bill
     * @param customerId Id of customer to attached Utilily bill
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadUtilityBill(body: Object, customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public uploadUtilityBill(body: Object, customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public uploadUtilityBill(body: Object, customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public uploadUtilityBill(body: Object, customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uploadUtilityBill.');
        }

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling uploadUtilityBill.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/pdf'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Customer>('put',`${this.basePath}/customers/${encodeURIComponent(String(customerId))}/utility-bill`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
