import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JsonAssetsService } from 'src/app/services/json-assets.services';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  @Input() profileForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private assets: JsonAssetsService) { }

  ngOnInit() {
    console.log('Form test:', this.profileForm);
  }

}
