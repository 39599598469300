// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   refreshURL: '/token',
//   authUrl: 'https://mbaas.desa.co.davivienda.com/auth/v1/token',
//   workflowUrl: 'https://mbaas.desa.co.davivienda.com/tmw/v1/workflow',
//   base: 'https://mbaas.desa.co.davivienda.com/tma',
//   chat: 'https://mbaas.desa.co.davivienda.com/soporte/v1/helpDesk',
//   siteKey: '6Ld8o6EUAAAAALo9T__Dph2zP-omzank-aSKJuNj',
//   actionRecaptcha: 'homepage',
//   MBAAS_PKEY_URL: 'https://mbaas.desa.co.davivienda.com',
//   app_base: '/tma/'
// };

export const environment = {
  production: true,
  refreshURL: '/token',
  authUrl: 'http://localhost:3000/api/auth',
  workflowUrl: 'http://localhost:3000/workflow',
  // base: 'http://localhost:4200',
  chat: 'http://localhost:3000/urlChat',
  siteKey: '6LdnmpUUAAAAAJ8Tk8x2Ersj50RDAZwfu54SaBNS',
  actionRecaptcha: 'homepage',
  MBAAS_PKEY_URL: 'http://localhost:3000',
  app_base: '/tma'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
